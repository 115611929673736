import "./App.css";
import React, { useRef, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import { Box } from "@material-ui/core";
import tbFormConfig from "./tbFormConfig";
import ChippedMultiselect from "./ChippedMultiselect";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
// import GFTypeSelect from "./GFTypeSelect";
import GFSelect from "./GFSelect";
import PropTypes from "prop-types";

import emailjs from "emailjs-com";

import useStyles from "./styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import short  from 'short-uuid';
import countries from "./commonSettings/countries";

export const SENDING_NONE = "none";
export const SENDING_IN_PROGRESS = "sending";
export const SENDING_FAILED = "failed";
export const SENDING_SUCCEEDED = "succeeded";
// import tbFormConfig from './tbFormConfig';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * If we'll need some more validation, it may make sense to move to Formik or Formik+Yup
 *
 * @param emailAddress can be null or empty
 */
function validateEmailAddress(emailAddress) {
  if (!emailAddress) {
    return "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailAddress)) {
    return "Invalid email address";
  }
  return null;
}

function GFApplicationForm(props) {
  const classes = useStyles();

  const locationRef = useRef();
  const locationRoleRef = useRef();
  const jobDescriptionRef = useRef();
  const requirementsRef = useRef();
  const responsibilitiesRef = useRef();
  const benefitsRef = useRef();
  const formRef = useRef();
  const [locationWarning, setLocationWarning] = useState(false);
  const [locationRoleWarning, setLocationRoleWarning] = useState(false);
  const [anotherRole, setAnotherRole] = useState(false);
  const [resumeWarning, setResumeWarning] = useState(false);
  const [salaryFrom, setSalaryFrom] = useState(0);
  const [salaryTo, setSalaryTo] = useState(25000);

  const countriesList = countries.map((country) => country.label);

  function specialitiesForCategory(category) {
    const categoryEntry = props.skillTree.find(
      (item) => Object.keys(item)[0] === category
    );
    const rawSpecList =
      (categoryEntry && categoryEntry[category].specialities) || [];
    const uniqSpecList = [...new Set(rawSpecList)];
    return uniqSpecList.sort();
  }

  // Go over array of category entries and for each entry get the very first key (as there is one only always)
  const categories = props.skillTree.map((value, key) => Object.keys(value)[0]);
  const workPreferences = props.employmentOptions.workPreferences;

  // const countriesList = [
  //   ...new Set(props.countries.map((value, key) => Object.values(value)[0])),
  // ];

  const seniorityLevels = props.employmentOptions.seniorityLevels;
  const skills = props.employmentOptions.skills;

  const employmentOptionsPossible = props.employmentOptions.employmentOptions;

  const [recruitValues, setRecruitValues] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    linkCompany: "",
    emailAddress: "",
    logoFile: null,
    aboutStudio: "",
    willAttendPGC: false
  });

  const [formValues, setFormValues] = useState({
    category: "",
    specialities: [],
    skills: [],
    gameGenres: [],
    gameEngines: [],
    workPreferences: [],
    artAndAnimationStyles: [],
    gamePlatforms: [],
    seniorityLevel: "",
    jobTitle: "",
    employmentOptions: [],
    country: "",
    roleLocation: [],
    salaryRange: [salaryFrom, salaryTo],
    responsibilities: "",
    benefits: "",
    requirements: "",
    jobDescription: "",
    linkJob: "",
    id: short.generate(),
  });

  const updateCategoryField = (e) => {
    // cleaning out the specialities field when category is changed
    doUpdateFields([
      { category: e.target.value },
      { specialities: [] }
    ]);
  };

  // Cannot be called several times in the update cycle as the next one will override the previous one
  const doUpdateFields = (nameValuePairs) => {
    let updateObject = { ...formValues };
    for (let entry of nameValuePairs) {
      const name = Object.keys(entry)[0];
      const value = entry[name];
      updateObject[name] = value;
    }
    // console.log('updateObject:', updateObject);
    setFormValues(updateObject);
  };

  // Cannot be called several times in the update cycle as the next one will override the previous one
  const doUpdateField = (fieldName, fieldValue) => { 
    
    setFormValues({
      ...formValues,
      [fieldName]: fieldValue,
    });
    setRecruitValues({
      ...recruitValues,
      [fieldName]: fieldValue,
    });
  };

  /**
   * "Known" as in we we are ready to display them
   * @type {{}}
   */
  const [knownErrors, setKnownErrors] = useState({
    emailAddress: null,
  });

  function validateEmailAddressFieldValue(emailAddressFieldValue) {
    const emailAddressError = validateEmailAddress(emailAddressFieldValue);
    setKnownErrors({
      emailAddress: emailAddressError,
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    let locEnabled = locationRef.current.querySelector("input").value !== "";
    let locRoleEnabled = true;
    if(formValues.roleLocation.length !== 0) {
      if(!countriesList.includes(formValues.roleLocation)){
        locRoleEnabled = false;
      }
    } else {
      locRoleEnabled = false;
    }
    if (locEnabled) {
      setLocationWarning(false);
    }
    if (locRoleEnabled) {
      setLocationRoleWarning(false);
    }
    if (knownErrors.emailAddress !== null) {
      console.log("Cannot submit form with invalid email address");
      return false;
    }

    if (locEnabled && locRoleEnabled) {
      // converts indices of employmentOptions to array of strings to post
      if (props.onSubmitWish) {
        if (anotherRole) {
          props.onSubmitWish(
            {
              ...formValues,
              ...recruitValues,
              salaryRange: [salaryFrom, salaryTo],
              id: short.generate(),
            },
            true
          );
          setFormValues(
            {
              ...formValues,
              category: "",
              specialities: [],
              skills: [],
              gameGenres: [],
              gameEngines: [],
              workPreferences: [],
              gamePlatforms: [],
              seniorityLevel: "",
              jobTitle: "",
              employmentOptions: [],
              country: "",
              roleLocation: [],
              salaryRange: [0, 25000],
              responsibilities: "",
              benefits: "",
              requirements: "",
              jobDescription: "",
            }
          );
          jobDescriptionRef.current.querySelector("textarea").value = "";
          requirementsRef.current.querySelector("textarea").value = "";
          responsibilitiesRef.current.querySelector("textarea").value = "";
          benefitsRef.current.querySelector("textarea").value = "";
        } else {
          props.onSubmitWish({
            ...formValues,
            ...recruitValues,
            salaryRange: [salaryFrom, salaryTo],
            id: short.generate(),
          });
        }

        emailjs
          .sendForm(
            "service_vlw2mas",
            "template_9yj6f1o",
            e.target,
            "_9UETZzyKZBjZd-wQ"
          )
          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
      }
    } else {
      if (!locEnabled) {
        setLocationWarning(true);
        window.scrollTo(0, locationRef.current.offsetTop);
      }
      if (!locRoleEnabled) {
        setLocationRoleWarning(true);
        window.scrollTo(0, locationRoleRef.current.offsetTop);
      }
    }
  }

  function specialitiesChanged(e, value, reason) {
    if (Array.isArray(value) && value.length > 3) {
      value = value.slice(0, 3);
    }
    doUpdateFields([{ specialities: value }]);
  }

  function skillsChanged(e, value, reason) {
    if (Array.isArray(value) && value.length > 5) {
      value = value.slice(0, 5);
    }
    doUpdateFields([{ skills: value }]);
  }

  function gameGenresChanged(e, value, reason) {
    doUpdateField("gameGenres", value);
  }

  function gameEnginesChanged(e, value, reason) {
    doUpdateField("gameEngines", value);
  }

  function gamePlatformsChanged(e, value, reason) {
    doUpdateField("gamePlatforms", value);
  }

  // function countryChanged(e, value, reason) {
  //   doUpdateField("countryRole", value);
  // }

  function employmentOptionChanged(e, value) {
    doUpdateField("employmentOptions", value);
  }

  function seniorityLevelChanged(e, value) {
    doUpdateField("seniorityLevel", e.target.value);
  }

  function workPreferencesChanged(e, value) {
    doUpdateField("workPreferences", value);
  }

  function rolePreferencesChanged(e, value) {
    doUpdateField("roleLocation", e.target.value);
  }

  function artAndAnimationStylesChanged(e, value, reason) {
    doUpdateField("artAndAnimationStyles", value);
  }

  function fileSelectionButtonLabel() {
    return formValues.logoFile ? formValues.logoFile.name : `Logo`;
  }

  const fileControlLabelClassName = formValues.logoFile
    ? classes.fileInputControlLabelFileSelected
    : classes.fileInputControlLabelFileNonSelected;

  const updateField = (e) => {
    // console.log('updateField', e.target.name, e.target.value, typeof e.target.value)
    let value = e.target.value;
    if (e.target.type === "checkbox") {
      value = e.target.checked && e.target.value;
    } else if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size / 1024 / 1024 < tbFormConfig.maxCVFileSizeMB) {
        value = e.target.files[0];
        setResumeWarning(false);
      } else {
        setResumeWarning(true);
      }
    }

    doUpdateField(e.target.name, value);
    if (e.target.name === "emailAddress") {
      validateEmailAddressFieldValue(value);
    }
  };
  const showAndAndAnimationStyles = formValues.category === "Art & Animation";

  return (
    <form name=" (place to put csv: https://github.com/GamesFactoryTalents/GameDevTalents--SubscriptionBoard/blob/f3afff00d2cb053c41c12dcc877b76315046f096/src/generated/vacancies.csv)" onSubmit={handleSubmit} ref={formRef}>
      <Grid
        className={classes.registrationForm}
        container
        spacing={4}
        direction="column"
        alignItems="stretch"
      >
        <Grid item className={classes.introText}>
          <h2>Fill up the form to submit your open job(s)</h2>
        </Grid>
        <Grid container item direction="row" xs={12} spacing={2}>
          <Grid item xs={6}>
            <FormControl required className={classes.formLine}>
              <InputLabel
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                First Name
              </InputLabel>
              <TextField
                required
                className={classes.textFieldInput}
                variant="filled"
                name="firstName"
                value={recruitValues.firstName}
                onChange={updateField}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl required className={classes.formLine}>
              <InputLabel
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                Last Name
              </InputLabel>
              <TextField
                required
                className={classes.textFieldInput}
                variant="filled"
                name="lastName"
                value={recruitValues.lastName}
                onChange={updateField}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item direction="row" xs={12} spacing={2}>
          <Grid item xs={6}>
            <FormControl required className={classes.formLine}>
              <InputLabel
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                Company Name
              </InputLabel>
              <TextField
                required
                className={classes.textFieldInput}
                variant="filled"
                name="companyName"
                value={recruitValues.companyName}
                onChange={updateField}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl required className={classes.formLine}>
              <InputLabel
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                Email Address
              </InputLabel>
              <TextField
                required
                type="email"
                className={classes.textFieldInput}
                variant="filled"
                name="emailAddress"
                error={knownErrors.emailAddress}
                helperText={knownErrors.emailAddress}
                value={recruitValues.emailAddress}
                onChange={updateField}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          item
          xs={6}
          style={{ display: "flex", gap: "30px", width: "100%" }}
        >
          {/*<Box
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  checked={showLogoCheckbox}
                  onChange={(event) => setShowLogo(event.target.checked)}
                  name="showLogo"
                />
              }
              label="Publish Company Logo along with the Role Listing"
            />
            <FormHelperText style={{ width: "270px", display: "flex" }}>
              <span>
                All Roles are published anonymously unless otherwise specified
              </span>
            </FormHelperText>
            </Box>*/}
            <Box>
              <FormControl className={classes.nonLineFormElement}>
                <InputLabel
                  id="cvFileLabel"
                  className={classes.inputLabelFile}
                  classes={{ asterisk: classes.inputLabelAsterisk }}
                  shrink
                >
                  Logo (Attachment)
                </InputLabel>
                <Button
                  className={classes.fileInputControl}
                  variant="contained"
                  component="label"
                  startIcon={<AttachFileIcon style={{ fontSize: 14 }} />}
                >
                  <span className={fileControlLabelClassName}>
                    {fileSelectionButtonLabel()}
                  </span>
                  <input
                    type="file"
                    name="logoFile"
                    id="cvFileInput"
                    onChange={updateField}
                    hidden
                  />
                </Button>
                {resumeWarning && (
                  <Alert severity="error">
                    Please select file less than 5GB!
                  </Alert>
                )}
              </FormControl>

              <FormHelperText>
                <span>Please select file less than 5GB</span>
              </FormHelperText>
            </Box>
        </Grid>
        <Grid container item direction="row" xs={12} spacing={2}>
          <Grid item xs={12}>
            <FormControl required className={classes.formLine}>
              <InputLabel
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                Link to company website (URL)
              </InputLabel>
              <TextField
                required
                className={classes.textFieldInput}
                variant="filled"
                name="linkCompany"
                type="url"
                value={recruitValues.linkCompany}
                onChange={updateField}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Divider />
        

        <Grid item className={classes.introText}>
          <h2>Role Specification</h2>
        </Grid>
        <Grid container item direction="row" xs={12} spacing={2}>
          <Grid item xs={12}>
            <FormControl required className={classes.formLine}>
              <InputLabel
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                Who are you looking for?
              </InputLabel>
              <TextField
                required
                className={classes.textFieldInput}
                variant="filled"
                name="jobTitle"
                value={formValues.jobTitle}
                onChange={updateField}
              />
            </FormControl>
            <FormHelperText>Please specify a job title</FormHelperText>
          </Grid>

          <Grid
            container
            item
            direction="row"
            xs={12}
            spacing={2}
            style={{ marginTop: "10px" }}
          >
            <Grid item xs={6}>
              <GFSelect
                name="seniorityLevel"
                labelText="Seniority Level"
                options={seniorityLevels}
                onChange={seniorityLevelChanged}
                value={formValues.seniorityLevel}
              />
            </Grid>

            <Grid item xs={6}>
              <GFSelect
                labelText="Category"
                name="category"
                options={categories}
                value={formValues.category}
                onChange={updateCategoryField}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "10px" }}>
            <ChippedMultiselect
              name="specialities"
              labelText="Specialisations"
              options={specialitiesForCategory(formValues.category)}
              onChange={specialitiesChanged}
              value={formValues.specialities}
            />
            <FormHelperText>
              Please specify max. 3 specialties within your category. If a
              specialty is not available in the list, enter it in the text field
            </FormHelperText>
          </Grid>

          <Grid item xs={12} hidden={!showAndAndAnimationStyles}>
            <ChippedMultiselect
              name="artAndAnimationStyles"
              labelText="Styles"
              options={props.employmentOptions.artAndAnimationStyles}
              onChange={artAndAnimationStylesChanged}
              value={formValues.artAndAnimationStyles}
              required={false}
            />
            <FormHelperText>
              Please specify max. 5 styles within your field. If your speciality
              is not available, enter it in the text field, then press Enter to
              submit it
            </FormHelperText>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "10px" }}>
            <ChippedMultiselect
              name="skills"
              labelText="Key Skills – Tech Stack, Technologies and Tools"
              options={skills}
              onChange={skillsChanged}
              value={formValues.skills}
            />
            <FormHelperText>
              Please list max. 5 tech skills and stack e.g. C++, Unity, Houdini
              etc. If a skill is not available in the list, enter it in the text
              field
            </FormHelperText>
          </Grid>

          <Grid
            container
            item
            direction="row"
            xs={12}
            spacing={2}
            style={{ marginTop: "10px" }}
          >
            <Grid item xs={6}>
              <ChippedMultiselect
                name="gameGenres"
                labelText="Game Genres"
                options={props.employmentOptions.gameGenres}
                onChange={gameGenresChanged}
                value={formValues.gameGenres}
                required={false}
              />
              <FormHelperText>
                Please list game genres. If a genre is not available in the
                list, enter them in the text field
              </FormHelperText>
            </Grid>

            <Grid item xs={6}>
              <ChippedMultiselect
                name="gameEngines"
                labelText="Game Engines"
                options={props.employmentOptions.gameEngines}
                onChange={gameEnginesChanged}
                value={formValues.gameEngines}
                required={false}
              />
              <FormHelperText>
                Please list game engines. If an engine is not available in the
                list, enter them in the text field
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            padding: "16px",
          }}
        >
          <Grid
            container
            item
            direction="row"
            xs={12}
            spacing={2}
            style={{ marginTop: "10px" }}
          >
            <Grid item xs={6}>
              <ChippedMultiselect
                name="gamePlatforms"
                labelText="Platforms"
                options={props.employmentOptions.platforms}
                onChange={gamePlatformsChanged}
                value={formValues.gamePlatforms}
                required={false}
              />
              <FormHelperText>
                Please list platforms. If a platform is not available in the
                list, enter them in the text field
              </FormHelperText>
            </Grid>

            <Grid item xs={6}>
              <p
                style={{
                  position: "absolute",
                  top: "20px",
                  margin: "0",
                  color: "black",
                  fontFamily: "Roboto",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                What is the salary range for this role?
              </p>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                  paddingTop: "10px",
                }}
              >
                <div className="p">{salaryFrom}</div>
                <div className="p">{salaryTo}</div>
              </Box>
              <RangeSlider
                min={0}
                max={25000}
                step={500}
                onInput={(event) => {
                  setSalaryFrom(event[0]);
                  setSalaryTo(event[1]);
                }}
                defaultValue={[0, 25000]}
              />
              <FormHelperText
                style={{
                  padding: "10px",
                  position: "absolute",
                  top: "30px",
                  right: "100px",
                }}
              >
                Monthly in Euros.
              </FormHelperText>
            </Grid>
          </Grid>
        </Box>

        <Grid
          container
          item
          direction="row"
          xs={12}
          spacing={2}
          style={{ marginTop: "10px" }}
        >
          <Grid item xs={6}>
            <ChippedMultiselect
              name="workPreferences"
              labelText="Work Preferences"
              options={workPreferences}
              onChange={workPreferencesChanged}
              value={formValues.workPreferences}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <ChippedMultiselect
              name="employmentOptions"
              labelText="Employment Options"
              options={employmentOptionsPossible}
              onChange={employmentOptionChanged}
              value={formValues.employmentOptions}
              required={true}
            />
          </Grid>
        </Grid>

        <Grid
          container
          item
          direction="row"
          xs={12}
          spacing={2}
          style={{ marginTop: "10px" }}
        >
          <Grid item xs={6} ref={locationRoleRef}>
              <GFSelect
                name="roleLocation"
                labelText="Role Location"
                options={countriesList}
                onChange={rolePreferencesChanged}
                value={formValues.roleLocation}
                required={true}
              />
              <FormHelperText>
                Please specify a country of the Role Location
              </FormHelperText>
              
            {locationRoleWarning && (
              <Alert severity="error">Please fill correct country!</Alert>
            )}
          </Grid>
          <Grid item xs={6} ref={locationRef}>
            <FormControl required className={classes.formLine}>
              <InputLabel
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                Preferred countries or area to hire from
              </InputLabel>
              <TextField
                className={classes.textFieldInput}
                variant="filled"
                name="country"
                value={formValues.country}
                onChange={updateField}
                required={true}
              />
            </FormControl>
            <FormHelperText>
              Please specify a country or area like European Union or Worldwide.
            </FormHelperText>
            {locationWarning && (
              <Alert severity="error">Please fill correct country!</Alert>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControl required className={classes.formLine}>
            <InputLabel
              className={classes.inputLabel}
              classes={{ asterisk: classes.inputLabelAsterisk }}
              shrink
            >
              Link to Job Opening
            </InputLabel>
            <TextField
              required
              className={classes.textFieldInput}
              variant="filled"
              name="linkJob"
              type="url"
              value={formValues.linkJob}
              onChange={updateField}
            />
          </FormControl>
        </Grid>


        <Grid item className={classes.introText}>
          <h3>Please fill in additional information.</h3>
        </Grid>

        <Grid container item direction="row" xs={12} spacing={0} ref={jobDescriptionRef}>
          <Grid item xs={12}>
            <FormControl required fullWidth className={classes.formLine}>
              <InputLabel
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                Job Description
              </InputLabel>
              <TextareaAutosize
                required  
                minRows={4}
                className={classes.textFieldInput}
                variant="filled"
                name="jobDescription"
                onChange={event => setFormValues({...formValues,jobDescription: event.target.value})}
              />
            </FormControl>
          </Grid>
        </Grid>

        {/* <Grid container item direction="row" xs={12} spacing={0}>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formLine}>
              <InputLabel
                required
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                Describe your Requirements
              </InputLabel>
              <TextField
                required
                className={classes.textFieldInput}
                multiline
                variant="filled"
                name="requirements"
                value={formValues.requirements}
                onChange={updateField}
              />
            </FormControl>
          </Grid>
        </Grid> */}

        {/* <Grid container item direction="row" xs={12} spacing={0}>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formLine}>
              <InputLabel
                required
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                Team Member would ideally also have
              </InputLabel>
              <TextField
                required
                className={classes.textFieldInput}
                multiline
                variant="filled"
                name="idealAdditions"
                value={formValues.idealAdditions}
                onChange={updateField}
              />
            </FormControl>
          </Grid>
        </Grid> */}

        <Grid container item direction="row" xs={12} spacing={0} ref={requirementsRef}>
          <Grid item xs={12}>
            <FormControl required fullWidth className={classes.formLine}>
              <InputLabel
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                Requirements
              </InputLabel>
              <TextareaAutosize
                minRows={4}
                required
                className={classes.textFieldInput}
                variant="filled"
                name="requirements"
                onChange={event => setFormValues({...formValues,requirements: event.target.value})}
              />
            </FormControl>
          </Grid>
        </Grid>

        {/* <Grid container item direction="row" xs={12} spacing={0}>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formLine}>
              <InputLabel
                required
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                Their Culture
              </InputLabel>
              <TextField
                required
                className={classes.textFieldInput}
                multiline
                variant="filled"
                name="theirCulture"
                value={formValues.theirCulture}
                onChange={updateField}
              />
            </FormControl>
          </Grid>
        </Grid> */}

        {/* <Grid container item direction="row" xs={12} spacing={0}>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formLine}>
              <InputLabel
                required
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                Reasons to work with them:
              </InputLabel>
              <TextField
                required
                className={classes.textFieldInput}
                multiline
                variant="filled"
                name="reasonsToWork"
                value={formValues.reasonsToWork}
                onChange={updateField}
              />
            </FormControl>
          </Grid>
        </Grid> */}

        <Grid container item direction="row" xs={12} spacing={0} ref={responsibilitiesRef}>
          <Grid item xs={12}>
            <FormControl required fullWidth className={classes.formLine}>
              <InputLabel
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                Responsibilities
              </InputLabel>
              <TextareaAutosize
                required
                minRows={4}
                className={classes.textFieldInput}
                variant="filled"
                name="responsibilities"
                onChange={event => setFormValues({...formValues,responsibilities: event.target.value})}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container item direction="row" xs={12} spacing={0} ref={benefitsRef}>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formLine}>
              <InputLabel
                className={classes.inputLabel}
                classes={{ asterisk: classes.inputLabelAsterisk }}
                shrink
              >
                Benefits
              </InputLabel>
              <TextareaAutosize
                minRows={4}
                className={classes.textFieldInput}
                variant="filled"
                name="benefits"
                onChange={event => setFormValues({...formValues,benefits: event.target.value})}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {props.sendingStatus === SENDING_FAILED && (
            <Alert className={classes.errorAlert} severity="error">
              We are sorry, something went wrong. Please, try again later or
              contact us by email.
              {props.errorMessage && " Server tells: "} {props.errorMessage}
            </Alert>
          )}
          {(!props.sendingStatus ||
            props.sendingStatus === SENDING_NONE ||
            props.sendingStatus === SENDING_FAILED) && (
            <Button
              type="submit"
              variant="contained"
              endIcon={<ArrowRightAltIcon />}
              onClick={() => setAnotherRole(false)}
              color="secondary"
            >
              Submit Now
            </Button>
          )}
          {(!props.sendingStatus ||
            props.sendingStatus === SENDING_NONE ||
            props.sendingStatus === SENDING_FAILED) && (
            <Button
              type="submit"
              variant="contained"
              endIcon={<ArrowRightAltIcon />}
              color="primary"
              onClick={() => setAnotherRole(true)}
              className="anotherRole"
            >
              Add Another Job
            </Button>
          )}
          {props.sendingStatus === SENDING_IN_PROGRESS && <CircularProgress />}
          {
            // For messages before posting such as e.g. file size limit
            props.sendingStatus === SENDING_NONE && props.errorMessage && (
              <Alert className={classes.errorAlert} severity="error">
                {props.errorMessage}
              </Alert>
            )
          }
          <Backdrop
            className={classes.backdrop}
            open={props.sendingStatus === SENDING_SUCCEEDED}
          >
            <Alert severity="success">
              Thank you! We will review your job submission and notify you once it is published.
            </Alert>
          </Backdrop>

          {/*props.recommendedCandidates.length > 0 && (
                  <Grid item xs={12}>
                    <p>Candidates IDS: </p>
                    {props.recommendedCandidates.map(cand => (<p>{cand['Candidate ID']}</p>))}
                  </Grid>
              )*/}
        </Grid>
      </Grid>
    </form>
  );
}

GFApplicationForm.propTypes = {
  sendingStatus: PropTypes.oneOf([
    SENDING_NONE,
    SENDING_IN_PROGRESS,
    SENDING_FAILED,
    SENDING_SUCCEEDED,
  ]),
  errorMessage: PropTypes.string,
  onSubmitWish: PropTypes.func,
  recommendedCandidates: PropTypes.array,
};

export default GFApplicationForm;
